<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
        class="align-self-end"
      >
        <dashboard-filter :filter="filter" @updateFilter="updateFilter"></dashboard-filter>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col
        cols="12"
        md="6"
        sm="6"
        order="1"
        class="align-self-end"
      >
        <dashboard-card-target-groups></dashboard-card-target-groups>
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="6"
        order="2"
        class="align-self-end"
      >
        <dashboard-card-ad-materials></dashboard-card-ad-materials>
      </v-col>

      <v-col
        cols="12"
        md="4"
        sm="6"
        order="3"
        class=""
      >
        <campaign-statistics />
      </v-col>

      <v-col
        cols="12"
        md="8"
        sm="6"
        order="3"
        class=""
      >
        <campaign-table />
      </v-col>

<!--      <v-col
        cols="12"
        md="3"
        sm="6"
        order="3"
        class="align-self-end"
      >
        <dashboard-card-campaigns
          label="Gesamt"
        ></dashboard-card-campaigns>
      </v-col>

<v-col
        cols="12"
        md="3"
        sm="6"
        order="3"
        class="align-self-end"
      >
        <dashboard-card-campaigns
          label="in Bearbeitung"
          state="inBearbeitung"
          color="secondary"
        ></dashboard-card-campaigns>
      </v-col>

      <v-col
        cols="12"
        md="3"
        sm="6"
        order="3"
        class="align-self-end"
      >
        <dashboard-card-campaigns
          label="Freigabe ausstehend"
          state="inInternerFreigabe"
          color="warning"
        ></dashboard-card-campaigns>
      </v-col>

      <v-col
        cols="12"
        md="3"
        sm="6"
        order="3"
        class="align-self-end"
      >
        <dashboard-card-campaigns
          label="Korrektur erwünscht"
          state="inBearbeitungKorrektur"
          color="accent"
        ></dashboard-card-campaigns>
      </v-col>

      <v-col
        cols="12"
        md="3"
        sm="6"
        order="3"
        class="align-self-end"
      >
        <dashboard-card-campaigns
          label="Freigegeben"
          state="inBezahlung"
          color="success"
        ></dashboard-card-campaigns>
      </v-col>

      <v-col
        cols="12"
        md="3"
        sm="6"
        order="3"
        class="align-self-end"
      >
        <dashboard-card-campaigns
          label="in Kontrolle durch Mailvista"
          state="inExternerFreigabe"
          color="warning"
        ></dashboard-card-campaigns>
      </v-col>

      <v-col
        cols="12"
        md="3"
        sm="6"
        order="3"
        class="align-self-end"
      >
        <dashboard-card-campaigns
          label="Versandbereit"
          state="inAnreicherung|inVersandstellung"
          color="info"
        ></dashboard-card-campaigns>
      </v-col>

      <v-col
        cols="12"
        md="3"
        sm="6"
        order="3"
        class="align-self-end"
      >
        <dashboard-card-campaigns
          label="Versendet"
          state="inVersendet"
          color="success"
        ></dashboard-card-campaigns>
      </v-col>-->
    </v-row>
  </div>
</template>

<script>
import {onUnmounted, onMounted, ref, getCurrentInstance} from '@vue/composition-api'

import DashboardFilter from '@/views/dashboard/DashboardFilter'
import DashboardCardTargetGroups from '@/views/dashboard/DashboardCardTargetGroups'
import DashboardCardAdMaterials from '@/views/dashboard/DashboardCardAdMaterials'
import DashboardCardCampaigns from '@/views/dashboard/DashboardCardCampaigns'
import DashboardCardCosts from '@/views/dashboard/DashboardCardCosts'
import DashboardCardClicks from '@/views/dashboard/DashboardCardClicks'
import DashboardCardAbos from '@/views/dashboard/DashboardCardAbos'
import DashboardCardProfit from '@/views/dashboard/DashboardCardProfit'
import DashboardCardOptOuts from '@/views/dashboard/DashboardCardOptOuts'
import DashboardChartClicks from '@/views/dashboard/DashboardChartClicks'
import DashboardChartExpansion from '@/views/dashboard/DashboardChartExpansion'
import DashboardTotalOverview from '@/views/dashboard/DashboardTotalOverview'

import store from '@/store'
import {
  mdiEmailOutline,
  mdiLockOutline,
  mdiEyeOffOutline,
  mdiEyeOutline
} from '@mdi/js'

import userStoreModule from '@/views/account/userStoreModule'
import axios from "axios";

import themeConfig from '@themeConfig'
import CampaignStatistics from "@/views/dashboard/CampaignStatistics";
import CampaignTable from "@/views/dashboard/CampaignTable";

export default {
  components: {
    CampaignTable,
    CampaignStatistics,
    DashboardFilter,
    DashboardCardTargetGroups,
    DashboardCardAdMaterials,
    DashboardCardCampaigns,
    DashboardCardCosts,
    DashboardCardClicks,
    DashboardCardAbos,
    DashboardCardProfit,
    DashboardCardOptOuts,
    DashboardChartClicks,
    DashboardChartExpansion,
    DashboardTotalOverview,
  },
  setup() {
    const loading = ref(true)
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    const filter = ref({
      idPtKanalListe:[]
    })

    const updateFilter = (newFilter) => {
      filter.value = newFilter;
    };

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
    vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    return {
      filter,
      updateFilter,
      icons: {
        mdiEmailOutline,
        mdiLockOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

#foto{
  display:none
}
.uploadImage{
  cursor: pointer;
}

</style>
