import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VDataTable,{staticClass:"table-rounded",attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.data.campaigns,"item-key":"id","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.kampagneName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"cursor":"pointer"},attrs:{"to":("/kampagne?filter[searchString]=" + (item.name)),"tag":"div"}},[_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{class:"v-avatar-light-bg info--text",attrs:{"color":item.avatar ? '' : item.avatarColor,"size":"30"}},[(item.adMaterial.format == 'maxipostkarte')?_c(VIcon,{staticClass:"info--text",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCardBulletedOutline)+" ")]):_vm._e(),(item.adMaterial.format == 'dina4mailing')?_c(VIcon,{staticClass:"info--text",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCardBulletedOutline)+" ")]):_vm._e()],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text--primary  font-weight-semibold text-truncate"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(item.zeitErstellungDE))])])],1)])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{class:("v-chip-light-bg " + (_vm.getCampaignStateColor(item.status)) + "--text font-weight-semibold"),attrs:{"small":"","color":_vm.getCampaignStateColor(item.status),"title":_vm.getCampaignStateDescription(item.status)}},[_vm._v(" "+_vm._s(_vm.getCampaignStateText(item.status))+" ")])]}},{key:"item.zielgruppe",fn:function(ref){
var item = ref.item;
return [(item.targetGroup.titel)?_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{class:"v-avatar-light-bg info--text",attrs:{"color":item.avatar ? '' : item.avatarColor,"size":"30"}},[(item.targetGroup.datenquelle == 'bestandsdaten')?_c(VIcon,{staticClass:"info--text",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiBullseyeArrow)+" ")]):_vm._e(),(item.targetGroup.datenquelle == 'eigenerUpload')?_c(VIcon,{staticClass:"info--text",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCloudUpload)+" ")]):_vm._e(),(item.targetGroup.datenquelle == 'fremderUpload')?_c(VIcon,{staticClass:"info--text",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiShareVariantOutline)+" ")]):_vm._e()],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text--primary  font-weight-semibold text-truncate"},[_vm._v(_vm._s(item.targetGroup.titel))])])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status == 'inBearbeitung' && item.status != 'inBearbeitungKorrektur')?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":'/kampagne/editieren/'+item.idPtKampagne}},[_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)],1)]}}],null,true)},[(item.status == 'inBearbeitungKorrektur')?_c('span',[_vm._v("Korrigieren")]):_c('span',[_vm._v("Bearbeiten")])]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }