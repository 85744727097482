<template>
    <v-card>
      <router-link :to="`/kampagne?filter[status]=${localState}`" >
      <div class="d-flex flex-column">

          <v-card-title class="text-base font-weight-semibold d-flex " :class="`${options.chipColor}--text`">
            <span  :class="`${options.chipColor}--text`">{{options.statTitle}}</span>
          </v-card-title>

          <v-card-text class="d-flex align-center">
            <h2 class="text-4xl font-weight-semibold" >
              <span :class="`${options.chipColor}--text`">{{ kFormatter(options.statistics,0) }}</span>
            </h2>
          </v-card-text>
          <v-card-text>
            <v-chip
              small
              :color="options.chipColor"
              class="v-chip-light-bg font-weight-semibold"
              :class="`${options.chipColor}--text`"
            >
              {{ options.chipText }}
            </v-chip>
          </v-card-text>


      </div>
      </router-link>
    </v-card>
</template>

<script>
import {onMounted,ref} from "@vue/composition-api";
import axios from "axios";
import { kFormatter } from '@core/utils/filter'

export default {
  props:{
    label : {
      type: String,
    },
    state: {
      type: String
    },
    color: {
      type: String
    }
  },
  setup(props) {

    const localLabel = props.label ? props.label : 'Gesamt';
    const localState = props.state ? props.state : 'all';
    const localColor = props.color ? props.color : 'primary';

    const options = ref({
      statTitle: 'Kampagnen',
      statistics: '13k',
      chipText: localLabel,
      chipColor: localColor,
      avatar: require('@/assets/images/avatars/12.png'),
      avatarWidth: '70',
    })

    onMounted(()=>{
      fetchData();
    })

    const fetchData = () => {
      axios.get('/api/ajaxServer/?aktion=fetchCampaigns&filter[status]='+localState)
        .then(
          (response) => {
            options.value.statistics = response.data.campaigns.length;
          }
        )
    }

    return {
      localState,
      options,
      kFormatter,
    }
  },
}
</script>

<style lang="scss" scoped>
.illustrator-img {
  position: absolute;
  right: 5%;
  bottom: 0;
}

.v-application {
  &.v-application--is-rtl {
    .illustrator-img {
      left: 5%;
      right: unset;
    }
  }
}

.v-card a{
  text-decoration: none;
}
</style>
