<template>
  <v-card>
    <v-data-table
      :headers="tableColumnHeaders"
      :items="data.campaigns"
      item-key="id"
      hide-default-footer
      class="table-rounded"
    >

      <template #[`item.kampagneName`]="{item}">

        <router-link :to="`/kampagne?filter[searchString]=${item.name}`" tag="div" style="cursor: pointer">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : item.avatarColor"
              :class="`v-avatar-light-bg info--text`"
              size="30"
            >
              <v-icon size="18" v-if="item.adMaterial.format == 'maxipostkarte'" class="info--text">
                {{ icons.mdiCardBulletedOutline  }}
              </v-icon>
              <v-icon size="18" v-if="item.adMaterial.format == 'dina4mailing'" class="info--text">
                {{ icons.mdiCardBulletedOutline  }}
              </v-icon>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <span class="d-block text--primary  font-weight-semibold text-truncate">{{ item.name }}</span>
              <span class="text-xs">{{ item.zeitErstellungDE }}</span>
            </div>
          </div>
        </router-link>
      </template>

      <template #[`item.status`]="{item}">
        <v-chip
          small
          :color="getCampaignStateColor(item.status)"
          :class="`v-chip-light-bg ${getCampaignStateColor(item.status)}--text font-weight-semibold`"
          :title="getCampaignStateDescription(item.status)"
        >
          {{ getCampaignStateText(item.status) }}
        </v-chip>
      </template>

      <template #[`item.zielgruppe`]="{item}">
        <div class="d-flex align-center" v-if="item.targetGroup.titel">
          <v-avatar
            :color="item.avatar ? '' : item.avatarColor"
            :class="`v-avatar-light-bg info--text`"
            size="30"
          >
            <v-icon size="18" v-if="item.targetGroup.datenquelle == 'bestandsdaten'" class="info--text">
              {{ icons.mdiBullseyeArrow }}
            </v-icon>
            <v-icon size="18" v-if="item.targetGroup.datenquelle == 'eigenerUpload'" class="info--text">
              {{ icons.mdiCloudUpload }}
            </v-icon>
            <v-icon size="18" v-if="item.targetGroup.datenquelle == 'fremderUpload'" class="info--text">
              {{ icons.mdiShareVariantOutline }}
            </v-icon>

          </v-avatar>
          <div class="d-flex flex-column ms-3">
            <span class="d-block text--primary  font-weight-semibold text-truncate">{{ item.targetGroup.titel }}</span>
          </div>
        </div>
      </template>

      <template #[`item.actions`]="{item}">
        <v-tooltip
          bottom
          v-if="item.status == 'inBearbeitung' && item.status != 'inBearbeitungKorrektur'"
        >
          <template #activator="{ on, attrs }">
            <router-link :to="'/kampagne/editieren/'+item.idPtKampagne" >
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </router-link>
          </template>
          <span v-if="item.status == 'inBearbeitungKorrektur'">Korrigieren</span>
          <span v-else>Bearbeiten</span>
        </v-tooltip>

      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import useHelper from "@/views/useHelper";
import {onMounted, ref} from '@vue/composition-api'
import axios from "axios";
import {
  mdiPencilOutline,
  mdiBullseyeArrow,
  mdiCloudUpload,
  mdiShareVariantOutline,
  mdiCardBulletedOutline ,
} from "@mdi/js";

export default {
  setup() {

    const data = ref({
      idPtKampagne : 0,
      targetGroup :{
        titel : "",
        datenquelle : "bestandsdaten"
      }
    });

    const tableColumnHeaders = [
      {
        text: '#ID',
        align: 'start',
        value: 'idPtKampagne',
        sortable : false
      },
      { text: 'Kampagne', value: 'kampagneName' , sortable : false},
      { text: 'Status', value: 'status' , sortable : false},
      { text: 'Zielgruppe', value: 'zielgruppe' , sortable : false},
      { text: 'Empfänger', value: 'maxEmpfaenger' , sortable : false, align: 'right'},
      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const {
      getCampaignStateColor,
      getCampaignStateDescription,
      getCampaignStateText
    } = useHelper()

    onMounted(()=>{
      axios.post('/api/dashboard/', {
        aktion: 'fetchLastCampaigns',
      })
        .then((response) => {
          data.value = response.data;
        })
        .catch((error) => {
          console.log(error)
        })
    })

    return {
      data,
      tableColumnHeaders,
      getCampaignStateColor,
      getCampaignStateDescription,
      getCampaignStateText,
      icons: {
        mdiPencilOutline,
        mdiBullseyeArrow,
        mdiCloudUpload,
        mdiShareVariantOutline,
        mdiCardBulletedOutline ,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.chart-wrapper {
  max-width: 130px;
}
.statistics-table {
  border-top: solid 1px rgba(93, 89, 98, 0.14);
  .badge-sm {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    margin: 4px;
  }
}

.noLinkStyles{
  text-decoration: none;
  color:black;
}
</style>
