<template>
  <v-card>
    <div class="d-flex">
      <div>
        <v-card-title class="text-base font-weight-semibold">
          {{options.statTitle}}
        </v-card-title>
        <v-card-text class="d-flex align-center">
          <h2 class="text-2xl font-weight-semibold">
            {{ kFormatter(options.statistics,0) }}
          </h2>
          <span
            class="text-xs ms-1"
            :class="checkChange(options.change) ? 'success--text':'error--text'"
          > {{ options.change }}</span>
        </v-card-text>
        <v-card-text>
          <v-chip
            small
            :color="options.chipColor"
            class="v-chip-light-bg font-weight-semibold"
            :class="`${options.chipColor}--text`"
          >
            {{ options.chipText }}
          </v-chip>
        </v-card-text>
      </div>
      <v-spacer></v-spacer>
      <div class="illustrator-img">
        <v-img
          :width="options.avatarWidth"
          :src="options.avatar"
        ></v-img>
      </div>
    </div>
  </v-card>
</template>

<script>
import {onMounted,ref,watch} from "@vue/composition-api";
import axios from "axios";
import { kFormatter } from '@core/utils/filter'

export default {
  props:{
    filter: {
      type: Object,
      default: () => {idPtKanalListe:[]}
    }
  },
  setup(props) {

    const currentYear = new Date().getFullYear();
    const options = ref({
      statTitle: 'Klicks',
      statistics: '13k',
      change: '+38%',
      chipText: `Aktuelles Jahr`,
      chipColor: 'primary',
      avatar: require('@/assets/images/avatars/9.png'),
      avatarWidth: '111',
    })

    const checkChange = (value) => {
      const firstChar = value.charAt(0)
      if (firstChar === '+') {
        return true
      }
      return false
    }

    onMounted(()=>{
      fetchData();
    })

    watch(() => props.filter, () => {
      fetchData();
    });

    const fetchData = () => {
      axios.post('/api/dashboard/', {
        aktion: 'fetchComparedPeriodsChannelStatistics',
        filter: props.filter,
        column: 'anzahlKlicks',
        period:{
          'base' : 'aktuellesJahr',
          'compare' : 'letztesJahrVergleichswert',
        },
      })
        .then((response) => {
          options.value.statistics = response.data.current;
          options.value.change = response.data.changeAbs > 0 ? response.data.changePerc : "";
        })
        .catch((error) => {
          console.log(error)
        })
    }



    return {
      options,
      checkChange,
      kFormatter,
    }
  },
}
</script>

<style lang="scss" scoped>
.illustrator-img {
  position: absolute;
  right: 5%;
  bottom: 0;
}

.v-application {
  &.v-application--is-rtl {
    .illustrator-img {
      left: 5%;
      right: unset;
    }
  }
}
</style>
