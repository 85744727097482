<template>
  <v-card>
    <router-link :to="`/zielgruppe`" >
    <div class="d-flex">
      <div>
        <v-card-title class="text-base font-weight-semibold">
          {{options.statTitle}}
        </v-card-title>
        <v-card-text class="d-flex align-center">
          <h2 class="text-2xl font-weight-semibold">
            {{ kFormatter(options.statistics,0) }}
          </h2>
        </v-card-text>
        <v-card-text>
          <v-chip
            small
            :color="options.chipColor"
            class="v-chip-light-bg font-weight-semibold"
            :class="`${options.chipColor}--text`"
          >
            {{ options.chipText }}
          </v-chip>
        </v-card-text>
      </div>
      <v-spacer></v-spacer>
      <div class="illustrator-img">
        <v-img
          :width="options.avatarWidth"
          :src="options.avatar"
        ></v-img>
      </div>
    </div>
    </router-link>
  </v-card>
</template>

<script>
import {onMounted,ref} from "@vue/composition-api";
import axios from "axios";
import { kFormatter } from '@core/utils/filter'

export default {
  setup() {

    const options = ref({
      statTitle: 'Zielgruppen',
      statistics: '13k',
      chipText: `Gesamt`,
      chipColor: 'primary',
      avatar: require('@/assets/images/avatars/9.png'),
      avatarWidth: '111',
    })

    onMounted(()=>{
      fetchData();
    })

    const fetchData = () => {
      axios.get('/api/ajaxServer/?aktion=fetchTargetGroups')
        .then(
          (response) => {
            options.value.statistics = response.data.targetGroups.length;
          }
        )
    }

    return {
      options,
      kFormatter,
    }
  },
}
</script>

<style lang="scss" scoped>
.illustrator-img {
  position: absolute;
  right: 5%;
  bottom: 0;
}

.v-application {
  &.v-application--is-rtl {
    .illustrator-img {
      left: 5%;
      right: unset;
    }
  }
}

.v-card a{
  text-decoration: none;
}
</style>
