<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Gesamtübersicht</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-list class="py-0">
        <v-list-item
          v-for="(data,index) in transactionData"
          :key="data.column"
          class="d-flex align-center px-0"
          :class="index > 0 ? 'mt-4':''"
        >
          <v-avatar
            size="40"
            rounded
            :color="data.avatarColor"
            :class="`v-avatar-light-bg ${data.avatarColor}--text me-3`"
          >
            <v-img
              max-height="20"
              max-width="20"
              contain
              :src="data.avatar"
            ></v-img>
          </v-avatar>

          <div class="d-flex align-center flex-grow-1 flex-wrap">
            <div class="me-2">
              <v-list-item-title class="text-sm font-weight-semibold">
                {{ data.title }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-xs">
                {{ data.subtitle }}
              </v-list-item-subtitle>
            </div>

            <v-spacer></v-spacer>

            <div class="d-flex align-center">
              <h4 class="text-base font-weight-semibold me-1">
                {{ data.transaction }}
              </h4>
              <v-icon
                size="20"
                :color="data.transaction.charAt(0) === '+' ? 'success' :'error'"
              >
                {{ data.transaction.charAt(0) === '+' ? icons.mdiChevronUp :icons.mdiChevronDown }}
              </v-icon>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import {onMounted, watch,ref} from "@vue/composition-api";
import axios from "axios";

export default {
  props:{
    filter: {
      type: Object,
      default: () => {idPtKanalListe:[]}
    }
  },
  setup(props) {

    const transactionData = ref([])

    onMounted(()=>{
      fetchData();
    })

    watch(() => props.filter, () => {
      fetchData();
    });

    const fetchData = () => {
      axios.post('/api/dashboard/', {
        aktion: 'fetchTotalOverviewStatistics',
        filter: props.filter,
      })
        .then((response) => {
          let data = [];
          response.data.stats.forEach(item=>{
            let avatar = null;
            switch(item.column){
              case "anzahlAbonnenten": avatar = require('@/assets/images/pages/paypal.png'); break;
              case "anzahlNachrichten": avatar = require('@/assets/images/pages/credit-card.png'); break;
              case "anzahlKampagnen": avatar = require('@/assets/images/pages/atm-card.png'); break;
              case "anzahlKlicks": avatar = require('@/assets/images/pages/wallet.png'); break;
              case "summeEinnahmen": avatar = require('@/assets/images/pages/arrow-growth.png'); break;
            }
            data.push({
              avatar : avatar,
              avatarColor : item.avatarColor,
              title : item.title,
              subtitle : item.subtitle,
              transaction : item.transaction,
            })
          })
          transactionData.value = data;
        })
        .catch((error) => {
          console.log(error)
        })
    }

    return {
      transactionData,
      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
}
</script>
