import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VRow,{staticClass:"ma-0 pa-0"},[_c(VCol,{attrs:{"cols":"8","sm":"6"}},[_c(VCardTitle,{staticClass:" d-flex flex-nowrap "},[_c('span',{staticClass:"text-no-wrap"},[_vm._v("So einfach funktioniert mailVista")])]),_c(VCardText,{staticClass:"d-flex align-center"},[_c('router-link',{attrs:{"to":'/zielgruppe/erstellen'}},[_c(VBtn,{staticClass:"me-3",attrs:{"color":"accent"}},[_c(VIcon,{staticClass:"pr-2",attrs:{"size":"30"}},[_vm._v(_vm._s(_vm.icons.mdiBullseyeArrow))]),_c('span',[_vm._v("Zielgruppe erstellen")])],1)],1),_c('router-link',{attrs:{"to":'/werbemittel/erstellen'}},[_c(VBtn,{staticClass:"me-3",attrs:{"color":"accent"}},[_c(VIcon,{staticClass:"pr-2",attrs:{"size":"30"}},[_vm._v(_vm._s(_vm.icons.mdiPencilRuler))]),_c('span',[_vm._v("Werbemittel erstellen")])],1)],1),(_vm.userData.stammdatenVollstaendig)?_c('router-link',{attrs:{"to":'/kampagne/erstellen'}},[_c(VBtn,{staticClass:"me-3",attrs:{"color":"accent"}},[_c(VIcon,{staticClass:"pr-2",attrs:{"size":"30"}},[_vm._v(_vm._s(_vm.icons.mdiAccountGroup))]),_c('span',[_vm._v("Kampagne erstellen")])],1)],1):_vm._e()],1)],1),_c(VCol,{attrs:{"cols":"4","sm":"6"}},[_c('div',[_c(VImg,{staticClass:"gamification-tree-4",attrs:{"width":"120","src":require("@/assets/images/misc/tree-4.png")}}),_c(VImg,{staticClass:"gamification-john-pose-2",attrs:{"width":"111","src":require("@/assets/images/3d-characters/pose-2.png")}}),_c(VImg,{staticClass:"gamification-tree",attrs:{"width":"100","src":require("@/assets/images/misc/tree.png")}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }