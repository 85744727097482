<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Kampagnenübersicht</span>
    </v-card-title>
    <v-card-text class="d-flex align-center justify-space-between pb-1 pt-5">
      <div>
        <p class="text-5xl font-weight-semibold text--primary mb-2">
          {{data.total}}
        </p>
        <span class="text-base">Gesamt</span>
      </div>
    </v-card-text>
    <v-card-text class="mt-2">
      <div
        v-for="(item,index) in data.stats"
        :key="item.name"
        :class="`statistics-table d-flex align-center pt-3 ${index > 0 ? 'mt-3':''}`"
        :title="getCampaignStateDescription(item.status)"
      >
        <span :class="`badge-sm ${getCampaignStateColor(item.status)}`"></span>
        <span class="text--primary ms-2">{{ getCampaignStateText(item.status) }}</span>
        <v-spacer></v-spacer>
        <span class="font-weight-semibold text--primary me-6">{{ item.anzahlKampagnen }}</span>
        <info-popup :title="getCampaignStateText(item.status)">{{ getCampaignStateDescription(item.status) }}</info-popup>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

import useHelper from "@/views/useHelper";
import {onMounted, ref} from '@vue/composition-api'
import axios from "axios";
import InfoPopup from "@/views/InfoPopup";

export default {
  components: {InfoPopup},
  setup() {

    const data = ref({});

    const {
      getCampaignStateColor,
      getCampaignStateText,
      getCampaignStateDescription
    } = useHelper()

    onMounted(()=>{
      axios.post('/api/dashboard/', {
        aktion: 'fetchCampaignOverviewStatistics',
      })
        .then((response) => {
          data.value = response.data;
        })
        .catch((error) => {
          console.log(error)
        })
    })

    return {
      data,
      getCampaignStateColor,
      getCampaignStateText,
      getCampaignStateDescription,
    }
  },
}
</script>

<style lang="scss" scoped>
.chart-wrapper {
  max-width: 130px;
}
.statistics-table {
  border-top: solid 1px rgba(93, 89, 98, 0.14);
  .badge-sm {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    margin: 4px;
  }
}
</style>
