<template>
  <v-card>
    <v-card-title class="align-start">
      <div>
        <p class="mb-2">
          Klicks
        </p>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <!-- Chart -->
      <vue-apex-charts
        height="300"
        :options="chartOptions"
        :series="chartData"
      ></vue-apex-charts>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'

import { getVuetify, addAlpha } from '@core/utils'
import {reactive,ref, onMounted, watch} from "@vue/composition-api";
import axios from "axios";

export default {
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
  },
  props:{
    filter: {
      type: Object,
      default: () => {idPtKanalListe:[]}
    }
  },
  setup(props) {
    const $vuetify = getVuetify()


    const chartOptions = ref({
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '60%',
          distributed: true,
          borderRadius: 4,
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa','So'],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickPlacement: 'on',
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        show: true,
      },
      grid: {
        show: false,
        padding: {
          top: -20,
          left: -10,
          right: -10,
        },
      },
    })
    const chartData = ref([
      {
        data: [40, 60, 50, 60, 90, 40, 50],
      },
    ])

    onMounted(()=>{
      fetchData();
    })

    watch(() => props.filter, () => {
      fetchData();
    });

    const fetchData = () => {
      axios.post('/api/dashboard/', {
        aktion: 'fetchWeekdaysStatistics',
        days: 6,
        filter: props.filter,
        column: 'anzahlKlicks',
      })
        .then((response) => {
          let data = [];
          let weekdays = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa','So'];
          let categories = [];
          response.data.stats.forEach((item,key) => {
            data.push(item.value);
            categories.push(weekdays[item.wochentag])
          });
          chartData.value = [{data: data}];

          let colors = [];
          for(let i = 0; i <= 6; i++){
            if(i == response.data.currentWeekday){
              colors.push($vuetify.theme.currentTheme.primary)
            }
            else{
              colors.push(addAlpha($vuetify.theme.currentTheme.primary, 0.1))
            }
          }
          chartOptions.value = {...chartOptions.value,...{colors:colors,xaxis: {categories:categories}}}
        })
        .catch((error) => {
          console.log(error)
        })
    }


    return {
      chartOptions,
      chartData,

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>
