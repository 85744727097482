import { render, staticRenderFns } from "./DashboardFilter.vue?vue&type=template&id=67fa2b9a&scoped=true&"
import script from "./DashboardFilter.vue?vue&type=script&lang=js&"
export * from "./DashboardFilter.vue?vue&type=script&lang=js&"
import style0 from "./DashboardFilter.vue?vue&type=style&index=0&id=67fa2b9a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67fa2b9a",
  null
  
)

export default component.exports