import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c('router-link',{attrs:{"to":"/werbemittel"}},[_c('div',{staticClass:"d-flex"},[_c('div',[_c(VCardTitle,{staticClass:"text-base font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.options.statTitle)+" ")]),_c(VCardText,{staticClass:"d-flex align-center"},[_c('h2',{staticClass:"text-2xl font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.kFormatter(_vm.options.statistics,0))+" ")])]),_c(VCardText,[_c(VChip,{staticClass:"v-chip-light-bg font-weight-semibold",class:((_vm.options.chipColor) + "--text"),attrs:{"small":"","color":_vm.options.chipColor}},[_vm._v(" "+_vm._s(_vm.options.chipText)+" ")])],1)],1),_c(VSpacer),_c('div',{staticClass:"illustrator-img"},[_c(VImg,{attrs:{"width":_vm.options.avatarWidth,"src":_vm.options.avatar}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }