<template>
  <v-card>
    <v-row class="ma-0">
      <v-col
        cols="12"
        sm="12"
        class="total-profit-chart-col"
      >
        <v-card-title class="pt-2">
          Wachstum
        </v-card-title>

        <vue-apex-charts
          id="total-profit-chart"
          height="320"
          :options="chartOptions"
          :series="chartData"
        ></vue-apex-charts>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd, mdiChartBar } from '@mdi/js'
import { getVuetify } from '@core/utils'
import { kFormatter } from '@core/utils/filter'
import {onMounted, watch,ref} from "@vue/composition-api";
import axios from "axios";

export default {
  components: {
    VueApexCharts,
  },
  props:{
    filter: {
      type: Object,
      default: () => {idPtKanalListe:[]}
    }
  },
  setup(props) {
    const $vuetify = getVuetify()

    const chartOptions = ref({
      colors: [
        $vuetify.theme.currentTheme.primary,
        $vuetify.theme.currentTheme.success,
        $vuetify.theme.currentTheme.secondary,
      ],
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '35%',
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      xaxis: {
        categories: [2016, 2017, 2018, 2019, 2020, 2021],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: value => kFormatter(value, 0),
        },
      },
      grid: {
        strokeDashArray: 7,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        curve: 'smooth',
        width: 6,
        lineCap: 'round',
        colors: ['#fff'],
      },
      responsive: [
        {
          breakpoint: 1400,
          options: {
            plotOptions: {
              bar: {
                borderRadius: 10,
                columnWidth: '45%',
              },
            },
          },
        },
      ],
    })

    const chartData = ref([
      {
        name: 'PRODUCT A',
        data: [22000, 25000, 19000, 30000, 21000, 35000],
      },
      {
        name: 'PRODUCT B',
        data: [16000, 11000, 15000, 0, 12000, 9000],
      },
      {
        name: 'PRODUCT C',
        data: [0, 0, 14000, 0, 11000, 12000],
      },
    ])

    onMounted(()=>{
      fetchData();
    })

    watch(() => props.filter, () => {
      fetchData();
    });

    const columns = ['anzahlKlicks','anzahlAbonnenten','anzahlAbmelder'];
    const groupBy = 'monat';

    const fetchData = () => {
      axios.post('/api/dashboard/', {
        aktion: 'fetchExpansionStatistics',
        filter: props.filter,
        columns: columns,
        groupBy: groupBy,
      })
        .then((response) => {
          let data = [];
          columns.forEach((c,k)=>{
            data[k] = {};
            data[k].name = c;
            data[k].data = [];
            response.data.stats.forEach((item,key) => {
              data[k].data.push(item[c]);
            })
          })
          chartData.value = data;

          let categories = [];
          response.data.stats.forEach((item,key) => {
            categories.push(item[groupBy])
          })
          chartOptions.value = {...chartOptions.value,...{xaxis:{categories:categories}}};
        })
        .catch((error) => {
          console.log(error)
        })
    }

    return {
      chartOptions,
      chartData,

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
        mdiChartBar,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .total-profit-chart-col {
    @include ltr() {
      border-right: thin solid;
    }
    @include rtl() {
      border-left: thin solid;
    }
  }
}
@include theme--child(total-profit-chart-col) using ($material) {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    border-color: map-get($material, 'dividers');
  }
}
</style>
